<template>
<div class="data-area">
  <div class="filter-area">
    <Form :model="filter" :label-width="90" inline>
      <FormItem label="设备ID">
        <Input v-model="filter.code" placeholder="设备ID" style="width: 200px" />
      </FormItem>
      <FormItem label="发生时间">
        <DatePicker type="date" v-model="filter.start" format="yyyy-MM-dd HH:mm:ss" style="width: 180px"></DatePicker>
      </FormItem>
      <FormItem label="至" :label-width="20">
        <DatePicker type="date" v-model="filter.end" format="yyyy-MM-dd HH:mm:ss" style="width: 180px"></DatePicker>
      </FormItem>
      <FormItem :label-width="0">
        <Button type="primary" @click="getList" :loading="loading">查询</Button>
        <!-- <Button type="info" @click="getCount" style="margin-left: 10px" :loading="loading">数量</Button> - {{count}} -->
      </FormItem>
    </Form>
  </div>
  <div class="list-area">
    <vxe-table empty-text="无数据" border resizable show-overflow show-header-overflow :data="list" height="auto" :row-config="{isHover: true}">
      <vxe-column type="seq" width="60" title="序号" fixed="left"></vxe-column>
      <vxe-column field="conn" title="连接" header-align="center" width="200"></vxe-column>
      <vxe-column field="fireTime" title="时间" header-align="center" width="160"></vxe-column>
      <vxe-column field="send" title="方向" header-align="center" width="60">
        <template #default="{ row }">
          <span class="show-protocol" :style="{color: `${!row.send ? 'green' : 'blue'}`}" @click="showPrococol(row)">{{row.send ? '发送' : '接收'}}</span>
        </template>
      </vxe-column>
      <vxe-column field="content" title="内容" header-align="center"></vxe-column>
      <vxe-column width="100" fixed="right">
        <template #default="{ row }">
          <Button type="primary" size="small" @click="copywxtap(row.content)">复制内容</Button>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
  <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
  <ModalProtocolResult v-model="showEditModal" :item="editItem" />
</div>
</template>
<script>
import { mapState } from 'vuex'
import ModalProtocolResult from './ModalProtocolResult'
export default {
  name: 'ModalTransLogs',
  components: {
    ModalProtocolResult
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('trans', ['connections']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
  },
  data(){
    return {
      showModal: this.value,
      showDebugModal: false,
      loading: false,
      filter: {
        code: '',
        start: '',
        end: '',
        index: 1,
        size: 20,
      },
      list: [],
      total: 0,
      showEditModal: false,
      editItem: {},
    }
  },
  created: function () {
  },
  mounted: function(){
    let now = new Date();
    // now.setHours(now.getHours() + 1);
    this.filter.end = now.todayEnd();
    // now.setHours(now.getHours() - 2);
    this.filter.start = now.todayStart();
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  destroyed: function(){
    // ws.stop();
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    showPrococol: function(params){
      // console.log('show protocol 4', params);
      if(params.send)return;
      this.editItem = {id: params.code, content: params.content };
      this.showEditModal = true;
      // console.log('show protocol', params, this.editItem, this.showEditModal);
    },
    copywxtap(con) {
      var input = document.createElement("input"); 
      input.value = con; 
      document.body.appendChild(input); 
      input.select(); 
      document.execCommand("Copy"); 
      document.body.removeChild(input); 
      this.$Message.success("复制内容成功");
    },
    getList(){
      if(this.filter.code.trim() == ''){
        this.$set(this, 'list', []);
        return;
      }
      this.filter.start = new Date(this.filter.start).format("yyyy-MM-dd HH:mm:ss");
      this.filter.end = new Date(this.filter.end).format("yyyy-MM-dd HH:mm:ss");
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/search/cmddata`, this.filter).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          // res.data.map(item => {
          //   item.send = item.send ? '发送' : '接收';
          // })
          this.$set(this, 'list', res.data.list);
          this.total = res.data.count;
        }
      });
    },
    handleRefresh: function(){
    },
    handleDebug: function(params){
      this.editItem = {data: params.row.ipAndPort, code: params.row.deviceCode};
      this.showDebugModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要断开连接[${params.row.ipAndPort}]吗?`,
        onOk: async () => {
          this.$store.dispatch('trans/disconnect', params.row.ipAndPort);
        }
      });
    },
    cancel: function(){
      this.showModal = false;
    }
  },
}
</script>
<style scoped>
.show-protocol{
  cursor: pointer;
}
.data-area{
  /* border: solid 1px red; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.filter-area{
  height: 40px;
  flex: none;
}
.list-area{
  height: 400px;
  flex: auto;
}
</style>